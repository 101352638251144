// Core
.ant-col {
  font-family: "Lato", sans-serif;
  min-height: auto;
}

:where(.css-dev-only-do-not-override-6j9yrn)[class^="ant-layout"],
:where(.css-dev-only-do-not-override-6j9yrn)[class*=" ant-layout"] {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  box-sizing: border-box;
}

// Input
.ant-input {
  height: 4.2rem;
  line-height: 4.4rem;
  display: block;
  width: 100%;
  text-indent: 1.2rem;
  font-size: 1.9rem;
  color: #262626;
  font-weight: 300;
  border-radius: 0;
  overflow: hidden;
  padding: 0;
  margin-bottom: 2.4rem;
  background: #ffffff;
  border: 0.1rem solid #084773;
  font-family: Lato, sans-serif;

  &::placeholder {
    color: #636562;
    font-size: 1.6rem;
    font-weight: 400;
  }

  &:hover {
    box-shadow: none;
    background: #ffffff;
    border: 0.1rem solid #084773;
  }

  &:focus {
    border: 0.1rem solid #084773 !important;
    box-shadow: none !important;
  }
}

.ant-input-number {
  width: calc(100% - 7.5rem);
  color: #000;
  font-size: 16px;
  font-weight: 400;
  height: 54px;
  border-radius: 3px;
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;

  .ant-input-number-input {
    color: #000;
    font-size: 2.3rem;
    font-weight: 400;
    height: 54px;
    padding: 0;
    font-family: "Lato", sans-serif;

    &::placeholder {
      color: #000;
    }
  }

  &::placeholder {
    color: #000;
  }

  &:hover {
    box-shadow: none;
    background: transparent;
    border-color: transparent;
  }
}

.ant-form-item {
  margin-bottom: 0;
}

// Selcet
.ant-select:not(.ant-select-customize-input) {
  box-shadow: none !important;

  .ant-select-selector {
    cursor: pointer;
    color: #cfd9db;
    font-size: 16px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    background: #52585a;
    line-height: 34px;
    border: 1px solid rgba(154, 163, 165, 0.35);
    border-radius: 3px;
    outline: none;
    box-shadow: none;

    .ant-select-selection-item {
      text-align: left;
      display: flex;
      align-items: center;
    }

    .ant-select-selection-search-input {
      position: relative;
      cursor: pointer !important;
      left: -10px;
      width: calc(100% - 20px);
      height: 32px !important;
      color: #818d8f !important;
      padding: 3px 10px;
      margin: 6px 0;
      border: none !important;
      outline: none !important;
      border-radius: 4px;
      background-color: #2d3537;
      opacity: 0;
      z-index: 1;
    }
  }

  &.ant-select-focused {
    box-shadow: none !important;

    .ant-select-selector {
      border: 1px solid rgba(154, 163, 165, 0.35) !important;

      .ant-select-selection-search-input {
        opacity: 1 !important;
      }
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: none !important;

    .ant-select-selector {
      background: #4d5355;
      border-color: rgba(173, 180, 182, 0.35) !important;
    }
  }

  .ant-select-arrow {
    inset-inline-end: 1px;
    margin-top: -4px;
  }
}

.ant-select-single {
  height: 46px;
  min-width: 212.5px;

  &.ant-select-open {
    .ant-select-selection-item {
      img {
        margin-right: 7px;
        height: 20px;
        width: 20px;
      }
    }
  }
  &.ant-select-show-arrow {
    .ant-select-selection-item {
      img {
        margin-right: 7px;
        height: 20px;
        width: 20px;
      }
    }
  }
}

// Select Dropdown
.ant-select-dropdown {
  color: #cfd9db;
  font-size: 16px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  background: #52585a;
  line-height: 34px;
  padding: 0;
  border: 1px solid rgba(154, 163, 165, 0.35);
  border-radius: 0px 0px 3px 3px;
  outline: none;
  box-shadow: none;
  margin-top: -5px;

  .ant-select-item {
    min-height: 46px;
    border-radius: 0;
    margin-right: 12px;

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      font-family: "Open Sans", sans-serif;

      img {
        margin-right: 7px;
        height: 20px;
        width: 20px;
      }
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #fff !important;
    font-weight: 600;
    background-color: #2d3537;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #323a3c;
  }

  &::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 100%;
    top: 0;
    right: 0;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #fff0;
  }
}

// Checkbox
.ant-checkbox-wrapper {
  width: 100%;
  margin-bottom: 1.8rem;

  &:hover {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #ecf0f6 !important;
        border-color: transparent !important;
      }
    }
  }
}

.ant-checkbox {
  align-self: flex-start;

  .ant-checkbox-inner {
    width: 2rem;
    height: 2rem;
    border: none;
    overflow: hidden;
    border-radius: 0.4rem;
    background-color: #ecf0f6;
    box-shadow: none;

    &:after {
      top: 9px;
      width: 7px;
      height: 11px;
      inset-inline-start: 29.5%;
      border: 2px solid #000;
      border-top: 0;
      border-inline-start: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 0;
    }
  }

  + span {
    color: #fff;
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 2rem;
    text-align: left;
    font-family: "Lato", sans-serif;
    padding-inline-start: 10px;

    a {
      color: #fff;
      font-weight: 400;
      text-decoration: underline !important;
    }
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    &:after {
      opacity: 1;
    }
  }
}

// Modal
.ant-modal {
  .ant-modal-title {
    font-size: 3rem;
    font-weight: 300;
    color: #153154;
    text-align: center;
    font-family: "Lato", sans-serif;
    margin-bottom: 2.4rem;
  }

  .ant-modal-close {
  }

  .ant-modal-content {
    padding: 3rem 5.5rem;
  }
}

// DropDown Lang
.ant-dropdown-placement-bottomLeft {
  .ant-dropdown-menu {
    color: #323232;
    font-size: 16px;
    font-weight: 300;
    font-family: "Lato", sans-serif;
    background: #f5f5f8;
    padding: 0;
    border: none;
    border-radius: 0px;
    outline: none;
    box-shadow: none;
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-inline-start: 40px;

    .ant-dropdown-menu-item {
      font-size: 2rem;
      text-align: left;
      border-radius: 0;
      font-weight: 300;
      font-family: "Lato", sans-serif;
      background-color: transparent !important;

      &:hover {
        background-color: #f5f5f8;
        text-decoration: underline !important;
      }
    }
  }
}

// Tabs
.ant-tabs-top {
  .ant-tabs-nav {
    &::before {
      display: none;
    }
  }
}

.ant-tabs {
  width: 100%;

  .ant-tabs-tab {
    width: 8rem;
    display: inline-block;
    line-height: 2.9rem;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 300;
    padding: 0;
    font-family: "Lato", sans-serif;
    border-bottom: 0.1rem solid #d6d6dc;

    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #000;
      }
    }

    &:hover {
      border-color: #d6d6dc;
      color: #d6d6dc;
    }
  }

  .ant-tabs-ink-bar {
    background: #085d94;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 1rem;
  }

  .ant-tabs-tab-btn:active {
    border-color: #d6d6dc;
    color: #d6d6dc;
  }

  .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      justify-content: flex-end;
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
}

// Radio
.ant-radio-group {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
}

.ant-radio-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 21rem;
  margin: 0 2rem;
  font-weight: 300;
  font-size: 1.8rem;
  float: left;
  border: none !important;
  padding-left: 2rem;
  padding-bottom: 1.2rem;
  padding-top: 1.2rem;
  border-radius: 0.5rem;
  height: 62px;
  cursor: pointer;

  &::before {
    display: none !important;
  }

  &:first-child {
  }

  s {
    color: #fff;
    flex: 1 1 auto;
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 28px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Lato", sans-serif;
    color: #000;
  }

  .logo {
    flex: 0 0 40px;
    margin-right: 12px;
    max-width: 40px;
    padding: 5px;
    transition: all 0.4s;

    img {
      display: block;
      width: 2.9rem;
      height: 2.9rem;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }

  span {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &:hover {
    background: #d6d6dc;
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #fdd638 !important;
  color: #323232 !important;

  &:hover {
    border-color: #06d8aa;
  }
}
