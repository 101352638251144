@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

@keyframes jumping {
  0% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(-25px);
  }
}

@keyframes online {
  0% {
    background-color: #61c788;
  }
  100% {
    background-color: #fff0;
  }
}
