/* -----------------------------------------------

Table of Content

	1. Body and Core Css
     - 1.1 Typography
     - 1.2 Button Style
     - 1.3 Form Style

  2. Home Page
     - 2.1 Banner Section

  3. Generator Page

----------------------------------- */

/* -----------------------------------
		    1. Body and Core Css
----------------------------------- */

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), url(../../fonts/LatoRegular.woff) format("woff");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), url(../../fonts/LatoLight.woff) format("woff");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), url(../../fonts/LatoBold.woff) format("woff");
}

* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  outline: none !important;
  list-style: none !important;
  text-decoration: none !important;

  &:after,
  &:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  font: {
    size: 14px;
    weight: 400;
    family: "Lato", sans-serif;
  }
  line-height: 1.42857143;
  color: #333;
}

main {
  min-height: 100%;
  height: auto;
  margin: 0 auto -6rem;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  min-width: 240px;
  background: #f5f5f8;
}

section {
  max-width: 1400px;
  margin: auto;
}

/* 1.1 Typography */
h1 {
  color: #fff;
  font-size: 36px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 0;
}

h3 {
  font-size: 1.75rem;
  margin-bottom: 0;
}

h4 {
  font-size: 1.5rem;
  margin-bottom: 0;
}

h5 {
  font-size: 1.25rem;
  margin-bottom: 0;
}

h6 {
  font-size: 1rem;
  margin-bottom: 0;
}

a {
  color: #343434;
  outline: none;
  text-decoration: none;

  &:hover {
    color: #126ba0;
    outline: none;
    text-decoration: none;
  }
}

p {
  font-size: 16px;
  color: #fff;
  margin-top: 0;
  margin-bottom: 1rem;
}

img {
  max-width: 100%;
}

dl,
ol,
ul {
  padding: 0;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

// Header
.ant-layout-header {
  position: relative;
  width: 100%;
  height: auto;
  display: block;
  background: #f5f5f8;
  font-size: 2rem;
  color: #343434;
  padding: 0;
  line-height: 1;
  font-weight: 300;
  z-index: 1;

  .head {
    .leftSide {
      float: left;
      width: 30%;
      padding-top: 1rem;
      position: relative;
      background: #ffffff;

      .leftSide__block {
        position: absolute;
        width: 100%;
        top: 0;
        padding-top: 1rem;

        .logo {
          text-align: center;
          padding-bottom: 3rem;
          background: #ffffff;
          padding-left: 3.6rem;
          padding-right: 3.6rem;

          a {
            img {
              max-width: 28rem;
              width: 100%;
            }
          }
        }

        .balance {
          background: #ffffff;
          padding: 3.6rem 3.6rem;
          overflow: hidden;
          padding-top: 0;

          span {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #d2d2de;
            padding: 1rem 0;
            font-weight: 600;
            font-size: 1.4rem;
            border-bottom: 0.1rem solid #e9e9e9;
          }

          ul {
            margin: 0;
            padding: 0;

            li {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 1rem 0;
              color: #1c1a1b;
              font-weight: 300;
              font-size: 1.6rem;
              border-bottom: 0.1rem solid #e9e9e9;
            }
          }
        }
      }
    }

    .rightSide {
      font-size: 1.8rem;
      color: #373737;
      margin-left: 30%;
      padding-left: 4rem;

      .rightSide__top {
        padding-bottom: 1rem;
        padding-top: 1rem;
        color: #126ba0;
        border-bottom: 0.1rem solid #136aa0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;

        .auth {
          margin-bottom: 10px;

          .reg {
            cursor: pointer;
            height: 3.8rem;
            line-height: 3.8rem;
            padding: 0 1.6rem;
            background: #fdd638;
            border: 0.2rem solid #fdd638;
            border-radius: 0.5rem;
            font-size: 1.6rem;
            font-weight: 300;
            color: #2c2c2c;
            display: inline-block;

            &:hover {
              background: transparent;
              border: 0.2rem solid #fdd638;
            }
          }

          .login {
            cursor: pointer;
            height: 3.8rem;
            line-height: 3.8rem;
            padding: 0 1.6rem;
            border: 0.2rem solid transparent;
            border-radius: 0.5rem;
            font-size: 1.6rem;
            font-weight: 400;
            display: inline-block;
            float: right;
            margin-bottom: 10px;

            &:hover {
              border: 0.2rem solid #fdd638;
              color: #2c2c2c;
            }
          }
        }

        .lang {
          text-align: right;
        }
      }

      .rightSide__menu {
        padding: 1.5rem 0;

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          color: #343434;
          font-size: 1.8rem;
          text-transform: uppercase;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;

          li {
            display: inline-block;

            a {
              padding: 0;
              height: 2.6rem;
              line-height: 2.6rem;
              display: inline-block;
              border-bottom: 0.1rem solid transparent;

              &.active {
                border-bottom: 0.1rem solid #126ba0;
                color: #126ba0;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }

  .head-mob {
    display: none;

    .head-mob__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 10rem;

      .menu {
        button {
          width: 3rem;
          height: 3rem;
          display: inline-block;
          border: none;
          background-size: 100%;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve' width='512px' height='512px'%3E%3Cg%3E%3Cg%3E%3Cpath d='M491.318,235.318H20.682C9.26,235.318,0,244.577,0,256s9.26,20.682,20.682,20.682h470.636 c11.423,0,20.682-9.259,20.682-20.682C512,244.578,502.741,235.318,491.318,235.318z' fill='%23126ba0'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpath d='M491.318,78.439H20.682C9.26,78.439,0,87.699,0,99.121c0,11.422,9.26,20.682,20.682,20.682h470.636 c11.423,0,20.682-9.26,20.682-20.682C512,87.699,502.741,78.439,491.318,78.439z' fill='%23126ba0'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpath d='M491.318,392.197H20.682C9.26,392.197,0,401.456,0,412.879s9.26,20.682,20.682,20.682h470.636 c11.423,0,20.682-9.259,20.682-20.682S502.741,392.197,491.318,392.197z' fill='%23126ba0'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
      }

      .authBlock {
        button {
          width: 3rem;
          height: 3rem;
          display: inline-block;
          border: none;
          background-size: 100%;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 485 485' style='enable-background:new 0 0 485 485;'%3E%3Cg%3E%3Cpolygon points='234.394,291.894 255.606,313.106 326.213,242.5 255.606,171.894 234.394,193.106 268.787,227.5 7.5,227.5 7.5,257.5 268.787,257.5 ' fill='%23126ba0'/%3E%3Cpolygon points='132.5,0 132.5,172.5 162.5,172.5 162.5,30 447.5,30 447.5,455 162.5,455 162.5,312.5 132.5,312.5 132.5,485 477.5,485 477.5,0 ' fill='%23126ba0'/%3E%3C/g%3E%3C/svg%3E");
        }
      }
    }

    .head-mob__lang {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-height: 5rem;
    }
  }

  .dropdown-btn {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    border: none;
    font-weight: 300;
    -webkit-user-select: none;
    user-select: none;
    justify-content: flex-end;
  }

  &.d-none {
    .head .leftSide .leftSide__block .balance {
      display: none;
    }
  }
}

.top-bar {
  background: #177baf;
  line-height: 1.6rem;
  color: #fff;
  padding: 1rem 1rem;
  font-weight: 500;
  text-align: center;
}

/* Exchange */
.exchange {
  padding-top: 3.6rem;
  padding-bottom: 3.6rem;
  background: url(../../img/background.jpg) top center no-repeat;
  background-size: cover;

  .exchange__block {
    position: relative;
    overflow: hidden;
    margin-left: 30%;
    padding-left: 4rem;

    h2 {
      font-size: 3rem;
      font-weight: 300;
      color: #ffffff;
      margin: 0;

      span {
        a {
          font-size: 1.8rem;
          display: block;
          margin-bottom: 3rem;
          color: #ffffff;
          font-weight: 300;
          text-decoration: underline !important;
        }
      }
    }

    .direct {
      display: flex;
      justify-content: space-between;

      .m-input {
        margin-left: 7.5rem;
      }

      .columnInput {
        width: 49%;
        flex: 1;
        margin-right: 1.5rem;
      }

      .columnOutput {
        width: 49%;
        flex: 1;
        margin-left: 1.5rem;
      }

      label {
        color: #ffffff;
        font-size: 2.2rem;
        font-weight: 300;
        line-height: 1;
        margin-bottom: 2rem;
        display: block;
      }

      .inputSelect {
        margin-bottom: 2rem;
        font-size: 2rem;
        font-weight: 300;
        display: flex;
        justify-content: space-between;

        .leftInfo {
          width: 70%;
          flex: 7;

          button {
            width: 100%;
            display: block;
            height: 5.4rem;
            line-height: 5.4rem;
            position: relative;
            padding-left: 5.6rem;
            background: #ffffff;
            color: #232323;
            text-align: left;
            border: 0.1rem solid #084773;

            .icon {
              display: flex;
              width: 3.2rem;
              height: 3.2rem;
              background-position: center left;
              background-repeat: no-repeat;
              background-size: 3.2rem;
              position: absolute;
              left: 1.2rem;
              top: 50%;
              margin-top: -1.7rem;
            }

            s {
              position: relative;
              top: -2px;
              font-weight: 300;
            }

            .arrow {
              position: absolute;
              right: 2px;
              top: 13px;
              display: inline-block;
              width: 4.5rem;
              height: 2.6rem;
              line-height: 2.6rem;
              background: #fff;
              color: #7f7f7f;
              font-size: 1.6rem;

              &::after {
                content: "";
                position: absolute;
                width: 4.5rem;
                height: 2.6rem;
                top: 50%;
                margin-top: -1.3rem;
                right: 0;
                background-image: url(../../img/icon/arrow-down.svg);
                background-size: 2.6rem;
                background-position: center;
                background-repeat: no-repeat;
                fill: #145896;
              }
            }
          }

          .inputValue {
            display: flex;
            align-items: center;
            background: #ffffff;
            border: 0.1rem solid #084773;

            .icon {
              position: absolute;
              width: 5.5rem;
              float: left;
              line-height: 5.4rem;
              text-align: left;
              font-size: 1.8rem;
              color: #16365e;
              font-weight: 300;
              text-indent: 1.2rem;
            }
          }
        }

        .rightInfo {
          width: 30%;
          flex: 3;
          display: flex;
          align-items: center;
          padding-left: 2rem;
          font-size: 1.6rem;
          line-height: 2rem;
          color: #fff;
        }
      }

      .info {
        text-align: left;
        font-weight: 300;
        font-size: 1.6rem;
        color: #ffffff;
        margin-bottom: 2.4rem;
      }
    }

    .exchangeRate {
      line-height: 3.4rem;
      font-weight: 300;
      font-size: 1.8rem;
      color: #ffffff;
      text-transform: uppercase;
      border-bottom: 0.1rem solid #ffffff;
      padding-bottom: 2rem;
      margin-bottom: 4rem;
      font-family: "Lato", sans-serif;
    }

    .exchangeData {
      display: flex;
      justify-content: space-between;

      .columnInput {
        width: 49%;
        flex: 1;
        margin-right: 1.5rem;
      }

      .columnOutput {
        width: 49%;
        flex: 1;
        margin-left: 1.5rem;
      }

      .captchaBlock {
        text-align: right;
        height: auto;

        .captchaimg {
          float: left;
          width: 45%;

          img {
            height: 5.2rem;
          }
        }

        .captchaInput {
          float: right;
          width: 49%;

          input {
            margin: 0;
          }
        }
      }
    }

    .exchangeFooter {
      margin-top: 2.4rem;
      display: flex;
      justify-content: space-between;

      .someText {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 1.6rem;
        display: flex;
        align-items: center;
      }

      .exchangeButton {
        padding-left: 2rem;

        .ant-btn {
          height: 5.2rem;
          line-height: 5.4rem;
          display: block;
          font-size: 1.8rem;
          color: #2c2c2c;
          font-weight: 400;
          background-color: #fdd638;
          background-image: none;
          border: none;
          font-family: "Lato", sans-serif;
          width: 23rem;
          padding: 0;
          text-align: center;
          margin: 0 auto;
          text-transform: uppercase;
          border-radius: 0.5rem;

          &:hover {
            background-color: #c2a52e;
          }
        }
      }
    }

    .infoFooter {
      position: relative;

      p {
        font-size: 1.6rem;
        font-weight: 300;
        line-height: 2rem;
        margin-bottom: 1.8rem;
        margin-top: 1.8rem;
        color: #ffffff;

        a {
          color: #ffffff;
          text-decoration: underline !important;
          text-decoration-color: #fdd638 !important;
        }
      }
    }

    .updateCourse {
      border-top: 0.1rem solid #ffffff;
      padding-top: 2rem;
      margin-top: 2rem;
      color: #ffffff;
      font-weight: 300;
      font-size: 1.6rem;
    }
  }
}

.advantage {
  font-weight: 300;
  padding-bottom: 3rem;
  padding-top: 3rem;
  background: #ffffff;
  color: #232323;
  position: relative;
  z-index: 2;
  border-top: 40px solid #f5f4f9;

  h1 {
    color: #232323;
    font-size: 3.1rem;
    margin-bottom: 2rem;
    font-weight: 300;
    padding-top: 2rem;
  }

  p {
    color: #232323;
    font-size: 1.8rem;
    margin-bottom: 3rem;
  }

  .priority {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .cell {
      width: 25%;

      .img {
        float: left;
        width: 60px;
      }

      .textBlock {
        margin-left: 60px;
        padding-left: 2rem;
        padding-right: 2rem;

        .title {
          font-size: 1.6rem;
          margin-bottom: 0.4rem;
          text-transform: uppercase;
        }

        .text {
          font-size: 1.4rem;
          line-height: 1.4;
        }
      }
    }
  }
}

/* Modal */
.tabs {
  position: relative;
  text-align: center;

  .tabs__content {
    display: inline-block;
    width: 50rem;

    .nav_tabs {
      display: flex;
      align-items: center;

      .nav_tabs__text {
        top: 0;
        left: 5rem;
        position: absolute;
        width: 10rem;
        display: inline-block;
        height: 2.7rem;
        line-height: 2.9rem;
        margin-left: 0;
        text-align: left;
        font-size: 1.4rem;
        font-family: "Lato", sans-serif;
      }
    }
  }
}

/* Order */
.order {
  padding-top: 3.6rem;
  padding-bottom: 3.6rem;
  background: url(../../img/background.jpg) top center no-repeat;
  background-size: cover;

  &.v2 {
    background: url(../../img/background2.jpg) top center no-repeat;
    background-size: auto;
  }

  .main {
    position: relative;
    overflow: hidden;
    margin-left: 30%;
    padding-left: 4rem;

    .box {
      padding: 3rem 5rem;
      background: #ffffff;
      border-radius: 0.5rem;
      margin: 0 auto;

      h1 {
        font-size: 2.4rem;
        color: #0b0b0b;
        font-weight: 300;
        line-height: 1;
        margin-bottom: 2.5rem;

        &.v2 {
          font-size: 2.9rem;
          text-align: center;
          margin-bottom: 3rem;
        }
      }

      p {
        margin-bottom: 2rem;
        font-size: 1.6rem;
        color: #0b0b0b;
        font-weight: 300;

        &.v2 {
          text-align: left;
          margin-bottom: 1rem;
          font-size: 1.8rem;
        }
      }

      .list {
        .title {
          font-size: 1.8rem;
          color: #0b0b0b;
          font-weight: 300;

          .number {
            display: inline-block;
            width: 3rem;
            height: 3rem;
            line-height: 3.2rem;
            font-size: 2rem;
            border-radius: 50%;
            background: #fdd638;
            color: #323232;
            font-weight: 600;
            text-align: center;
            margin-right: 1.5rem;
          }
        }

        .ticket {
          margin-left: 4rem;
          border-radius: 0.5rem;
          overflow: hidden;
          margin-top: 1rem;
          background-color: #ecf0f6;
          padding: 2rem 2rem;
          padding-bottom: 1rem;
          color: #000000;
          margin-bottom: 3rem;
          font-size: 1.7rem;
          font-weight: 300;

          .cell {
            content: " ";
            display: table;
            width: 100%;
            color: #0b0b0b;
            line-height: 2.2rem;
            margin-bottom: 0.5rem;

            .cell__title {
              float: left;
              width: 30%;
            }

            .cell__data {
              float: left;
              width: 69%;
              word-wrap: break-word;
              word-break: break-all;

              span {
                font-weight: 400;
              }

              img {
                display: inline-block;
                width: 2.2rem;
                height: 2.2rem;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 2.2rem;
                margin-right: 1rem;
              }
            }

            &:nth-child(2) {
              margin-bottom: 1rem;
            }

            &.v2 {
              .cell__title {
                width: 20%;
                margin-right: 2.5%;
              }

              .cell__data {
                width: 55%;
                margin-right: 2.4%;
              }

              .cell__copy {
                float: left;
                width: 20%;

                button {
                  display: inline-block;
                  padding: 0.2rem 1rem;
                  border-radius: 0.5rem;
                  background-color: #fdd638;
                  color: #323232;
                  font-size: 1.6rem;
                  border: none;

                  &:hover {
                    background-color: #145896;
                    color: #ffffff;
                  }
                }
              }
            }
          }
        }
      }

      .listButton {
        margin-top: 3rem;

        .paid,
        .cancel {
          cursor: pointer;
          height: 5.2rem;
          line-height: 5.4rem;
          display: inline-block;
          font-size: 1.9rem;
          color: #232323;
          font-weight: 400;
          background-color: #fdd638;
          background-image: none;
          border: none;
          margin-right: 5rem;
          border-radius: 0.5rem;
          font-family: Lato, sans-serif;
          text-transform: uppercase;
          width: 20rem;
          text-align: center;
          position: relative;

          &:hover {
            background-color: #145896;
            color: #ffffff;
          }
        }

        .cancel {
          background-color: #ecf0f6;
          color: #000000;
          font-weight: 300;

          &:hover {
            color: #000000;
            background-color: #cbcfd5;
          }
        }
      }

      .back {
        margin-top: 4rem;
        text-align: center;
        margin-bottom: 1rem;

        button {
          cursor: pointer;
          height: 5.2rem;
          line-height: 5.4rem;
          display: inline-block;
          font-size: 1.9rem;
          color: #323232;
          font-weight: 300;
          background-color: #fdd638;
          background-image: none;
          border: none;
          border-radius: 0.5rem;
          font-family: Lato, sans-serif;
          padding: 0 3rem;
          text-align: center;
          position: relative;
        }
      }
    }

    &.v2 {
      margin-left: 0;
      padding-left: 0;

      .title {
        font-size: 3.7rem;
        color: #ffffff;
        font-weight: 300;
        line-height: 1.4;
        padding: 0rem 0 1rem;
        text-align: center;
        font-family: Lato, sans-serif;
      }

      .box {
        margin: 0 10%;
        padding: 5rem 10rem;

        h4 {
          font-size: 1.8rem;
          font-weight: 700;
          margin: 1.7rem 0 0;
        }

        p {
          font-size: 1.8rem;
          margin-bottom: 0;
          font-weight: 400;
        }

        ul {
          display: block;
          list-style-type: disc;
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-inline-start: 40px;
          unicode-bidi: isolate;

          li {
            list-style: disc !important;
          }
        }

        .contact__block {
          display: flex;
          justify-content: space-between;
          margin-bottom: 3rem;

          .cell {
            max-width: 45%;
            flex-grow: 1;

            h3 {
              font-family: Lato, sans-serif;
              font-weight: 300;
              font-size: 2.5rem;
              margin-bottom: 2rem;
            }

            p {
              font-weight: 300;
            }

            .best {
              display: flex;
              align-items: center;
              text-decoration: underline !important;
              margin: 17px 0 10px;
              font-weight: 300;

              span {
                display: inline-block;
                width: 20px;
                height: 20px;
                line-height: 20px;
                background-image: url(../../img/icon/bestchange.ico);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 20px;
                margin-right: 10px;
                margin-bottom: 0px;
              }

              &:hover {
                text-decoration: none !important;
              }
            }

            .item {
              display: table;
              content: " ";
              margin-bottom: 2rem;

              .icon {
                display: inline-block;
                width: 4rem;
                height: 4rem;
                float: left;

                span {
                  display: inline-block;
                  width: 4rem;
                  height: 4rem;
                  background-size: 2.4rem;
                  background-repeat: no-repeat;
                  background-position: 50%;
                  border-radius: 50%;
                  background-color: #126ba0;
                  background-image: url(../../img/icon/email.svg);
                }
              }

              .desc {
                font-weight: 300;
                margin-left: 6rem;

                span {
                  font-size: 1.6rem;
                  margin-bottom: 0.1rem;
                  display: block;
                }

                b {
                  font-weight: 400;
                  font-size: 1.9rem;
                }

                a {
                  border-bottom: 0.05rem solid #3789b2;

                  &:hover {
                    border-bottom: 0.05rem solid transparent;
                    color: #3789b2;
                  }
                }

                s {
                  font-size: 1.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Footer */
.footer {
  background: #085d94;
  padding: 3.5rem 0;
  color: #ffffff;
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: 300;
}

.tooltip-inner {
  text-align: left;
  font-family: Lato, sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  padding: 1rem 1.2rem;
  background-color: #d71b1b;
  color: #ffeaea;
  max-width: 100%;
  word-wrap: break-word;
  border-radius: 4px;
  text-transform: none;
  margin-bottom: 5px;
  top: -6px;
  position: relative;
  display: none;

  &.active {
    display: block;
  }
}
