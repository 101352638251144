/* xxl, large: 1350px */
$breakpoint-xxl: 1350px;
/* xl, large: 1200px */
$breakpoint-xl: 1200px;
/* lg, large: 992px */
$breakpoint-lg: 992px;
/* md, large: 768px */
$breakpoint-md: 768px;
/* sm, large: 575px */
$breakpoint-sm: 575px;

@media (max-width: 1500px) {
  html {
    font-size: 9px;
  }

  section {
    max-width: 1400px;
    margin: 0 12rem;
  }
}

@media screen and (max-width: $breakpoint-xl) {
  html {
    font-size: 8.5px;
  }

  section {
    margin: 0 3rem;
  }
}

@media screen and (max-width: $breakpoint-lg) {
  html {
    font-size: 11px;
  }

  .ant-layout-header {
    .head {
      display: none;
    }

    .head-mob {
      display: block;
    }
  }

  .exchange {
    .exchange__block {
      margin-left: 0;
      padding-left: 0;

      h2 {
        font-size: 2.4rem;
      }

      .direct {
        flex-direction: column;

        .columnInput,
        .columnOutput {
          width: 100%;
          margin: 0;
        }
      }

      .exchangeData {
        flex-direction: column;

        .columnInput,
        .columnOutput {
          width: 100%;
          margin: 0;
        }
      }

      .exchangeFooter {
        flex-direction: column;
      }
    }
  }

  .advantage {
    .priority {
      flex-direction: column;

      .cell {
        width: 100%;
        margin-right: 0;
        margin-bottom: 4rem;
      }
    }
  }

  .order {
    .main {
      margin-left: 0;
      padding-left: 0;

      .box {
        .list {
          .ticket {
            .cell {
              &.v2 {
                display: flex;
                flex-direction: column;
                gap: 6px;
                margin-bottom: 15px;

                .cell__title,
                .cell__data,
                .cell__copy {
                  width: 100%;
                }
              }
            }
          }
        }

        .listButton {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
        }
      }

      &.v2 {
        .box {
          padding: 2rem 3rem;
          margin: 0;

          .contact__block {
            flex-direction: column;

            .cell {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
}

@media screen and (max-width: $breakpoint-sm) {
  html {
    font-size: 8px;
  }
}
